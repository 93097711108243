"use client";

import React, { useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";

type ErrorProps = {
  error: Error & { digest?: string };
  reset: () => void;
};

const ErrorComponent: React.FC<ErrorProps> = ({ error, reset }) => {
  useEffect(() => {
    console.error(error);
  }, [error]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        textAlign: "center",
        padding: 2,
      }}
    >
      <Typography variant="h4" sx={{ mb: 2 }}>
        Something went wrong!
      </Typography>
      <Typography variant="body1" sx={{ mb: 4 }}>
        {error.message || "An unexpected error occurred."}
      </Typography>
      <Button variant="contained" color="primary" onClick={reset}>
        Try Again
      </Button>
    </Box>
  );
};

export default ErrorComponent;
